import './Perfil.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Perfil from '../../img/Perfil.png';

const fetchData = async (url, jwtToken) => {
    try {
        const response = await axios.get(url, {
            headers: {
                'Authorization': 'Bearer ' + jwtToken,
                'Content-Type': 'application/json'
            },
        });
        return response.data;
    } catch (error) {
        console.error("Hubo un error en la petición: ", error);
        return null;
    }
};

const MiPerfil = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const jwtToken = localStorage.getItem('accessToken') || Cookies.get('jwtToken');
    const navigate = useNavigate();
    const { id, contador } = useParams();
    const [match, setMatch] = useState(null);
    const [busqueda, setBusqueda] = useState(null);


    useEffect(() => {
        if (!jwtToken) {
            setIsLoggedIn(false);
            navigate("/");
        } else {
            setIsLoggedIn(true);
            fetchData(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/obtenerids/match/${id}`, jwtToken)
                .then(data => {
                    if (data) {
                        setMatch(data);
                    }
                });
        }
    }, [id, jwtToken, navigate]);


    useEffect(() => {
        if (match && match.id_busqueda) {
            fetchData(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/busqueda/${match.id_busqueda}`, jwtToken)
                .then(data => {
                    if (data) {
                        setBusqueda(data);
                    }
                });
        }
    }, [match, jwtToken]);

    if (!match) return 'Cargando...'; 
    console.log(match)
    return (
        <>
        <div className='div_profile'></div>
        {isLoggedIn && (
          <div className="desktop_perfil">
            <div className="div">
              <div className="overlap-group">
                <div className="rectangulo_principal">
                    <div className="flex-container">
                        <div className="group"></div>
                        <div className="rectangle-fucsia"></div>
                    </div>
                    <div className="rectangle-gris"></div>
                    <div className="linea_celeste"></div>
                    <div className="linea_celeste_corta"></div>
                    
                    <div className="porcentaje-container">
                        <div className="elipse-celeste"></div>
                        <h3 className='porcen'>{(match.correspondencia * 100).toFixed(0)}%</h3>
                    </div>
                    
                    <div className="rectangulo_seccion">
                        <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap' rel='stylesheet'></link>
                        <h3 className='num_candidata'>Candidata {contador}</h3>
                        <h3 className='cargo_candidata'>{match?.usuaria?.cargo?.cargo || ""} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{match?.usuaria?.areas.map(area => area.nommbre).join(' ') || ""}</h3>
                        {/* manejar el array de areas */}
                        <h3 className='brief_candidata'>{match?.usuaria?.brief}</h3>

                        <div className="perfil-info-container">
                            <img src={Perfil} alt="Perfil" className="perfil-image" />
                            <div className="info-usuaria">
                                {match.usuaria.profesion && (
                                    <>
                                        <h3 className='usuaria-title'>Profesion</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.profesion.map(profesion => profesion.nombre).join(', ')}</h3>
                                    </>
                                )}

                                {match?.usuaria?.universidad && (
                                    <>
                                        <h3 className='usuaria-title'>Universidad</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.universidad}</h3>
                                    </>
                                )}

                                {match?.usuaria?.postgrado && (
                                    <>
                                        <h3 className='usuaria-title'>Postgrado</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.postgrado}</h3>
                                    </>
                                )}

                                {match.usuaria.aniosExperiencia && (
                                    <>
                                        <h3 className='usuaria-title'>Años Experiencia</h3>
                                        <h3 className='usuaria-resp'>{`${match.usuaria.aniosExperiencia.rango_experiencia_desde} - ${match.usuaria.aniosExperiencia.rango_experiencia_hasta}`}</h3>
                                    </>
                                )}

                                {match?.usuaria?.intereses && (
                                    <>
                                        <h3 className='usuaria-title'>Intereses</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.intereses}</h3>
                                    </>
                                )}

                                {match.usuaria.cargo && (
                                    <>
                                        <h3 className='usuaria-title'>Cargo Actual</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.cargo.cargo}</h3>
                                    </>
                                )}

                                {match.usuaria.industria && (
                                    <>
                                        <h3 className='usuaria-title'>Industria Actual</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.industria.nombre_industria}</h3>
                                        
                                    </>
                                )}

                                {match.usuaria.aditionalCargo && (
                                    <>
                                        <h3 className='usuaria-title'>Cargo Adicional</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.aditionalCargo.cargo}</h3>
                                        {/* ver como poner el map */}
                                    </>
                                )}

                                {match.usuaria.aditionalIndustria && (
                                    <>
                                        <h3 className='usuaria-title'>Industria Adicional</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.aditionalIndustria.nombre_industria}</h3>
                                    </>
                                )}

                                {match.usuaria.industrias && (
                                    <>
                                        <h3 className='usuaria-title'>Industrias Experiencia</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.industrias.map(industrias => industrias.nombre_industria).join(', ')}</h3>
                                        {/* ver el map  */}
                                    </>
                                )}

                                {match.usuaria.areas && (
                                    <>
                                        <h3 className='usuaria-title'>Áreas de Experiencia</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.areas.map(areas => areas.nombre).join(', ')}</h3>
                                        {/* manejar array  */}
                                    </>
                                )}

                                {match.usuaria.competencia  && (
                                    <>
                                        <h3 className='usuaria-title'>Competencias</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.competencia.map(competencia => competencia.competencia).join(', ')}</h3>
                                        {/* manejar el array */}
                                    </>
                                )}

                                {match.usuaria.disponibilidad && (
                                    <>
                                        <h3 className='usuaria-title'>Disponibilidad</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.disponibilidad.map(disponibilidad => disponibilidad.disponibilidad).join(', ')}</h3>
                                    </>
                                )}

                                {match?.usuaria?.disposicion_viajar && (
                                    <>
                                        <h3 className='usuaria-title'>Disposición para Viajar</h3>
                                        <h3 className='usuaria-resp'>Si</h3>
                                    </>
                                )}

                                {match?.usuaria?.tipoModalidad && (
                                    <>
                                        <h3 className='usuaria-title'>Modalidad</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.tipoModalidad.tipoModalidad}</h3>
                                    </>
                                )}

                                {match?.usuaria?.tipoJornada && (
                                    <>
                                        <h3 className='usuaria-title'>Jornada</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.tipoJornada.tipoJornada}</h3>
                                    </>
                                )}

                                {match?.usuaria?.factor && (
                                    <>
                                        <h3 className='usuaria-title'>Factor de Inclusión</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.factor}</h3>
                                    </>
                                )}

                                {match?.usuaria?.nombrePuebloOriginario && (
                                    <>
                                        <h3 className='usuaria-title'>Pueblo Originario</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.nombrePuebloOriginario}</h3>
                                    </>
                                )}

                                {match?.usuaria?.posibilidadCambiarseRegion && (
                                    <>
                                        <h3 className='usuaria-title'>Posibilidad de Cambio de Región</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.posibilidadCambiarseRegion.posibilidad}</h3>
                                    </>
                                )}

                                {match.usuaria.idiomas && (
                                    <>
                                        <h3 className='usuaria-title'>Idiomas</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.idiomas.map(idioma => idioma.nombre).join(', ')}</h3>
                                        {/* manejar el array */}
                                    </>
                                )}

                                {match?.usuaria?.altaDireccion && (
                                    <>
                                        <h3 className='usuaria-title'>Formación en Alta Dirección</h3>
                                        <h3 className='usuaria-resp'>Si</h3>
                                    </>
                                )}

                                {match?.usuaria?.experienciaDirectorios && (
                                    <>
                                        <h3 className='usuaria-title'>Experiencia en Directorios</h3>
                                        <h3 className='usuaria-resp'>Si</h3>
                                    </>
                                )}

                                {match?.usuaria?.personalidad && (
                                    <>
                                        <h3 className='usuaria-title'>Test Personalidad</h3>
                                        <h3 className='usuaria-resp'>{match.usuaria.personalidad.personalidad}</h3>
                                    </>
                                )}
                            </div>

                        </div>
                    </div>
                    <div className="space-bottom"></div> {/* Espacio al final del rectángulo principal */}
                </div>
                <div className='rectangulo-arriba'>
                    <h3 className='message-home3'>Información de búsqueda</h3>
                    <div className="rectangle3"></div>
                    <h3 className='message-home2'>{busqueda?.nombre}</h3>
                  
                    <div className="rectangle3"></div>
                    <h3 className='busqueda-title'>Cargo:</h3>
                    <h3 className='busqueda-resp'>{busqueda?.cargo?.disponibilidad}</h3>
                    
                    <h3 className='busqueda-title'>Área:</h3>
                    <h3 className='busqueda-resp'>{busqueda?.areas?.[0]?.nombre}</h3>
                    
                    <h3 className='busqueda-title'>Profesiones:</h3>
                    <h3 className='busqueda-resp'>{busqueda?.profesiones?.[0]?.nombre}</h3>
                    
                    <h3 className='busqueda-title'>Jornada:</h3>
                    <h3 className='busqueda-resp'>{busqueda?.jornada?.tipoJornada}</h3>
                    
                    <h3 className='busqueda-title'>Modalidad:</h3>
                    <h3 className='busqueda-resp'>{busqueda?.modalidad?.tipoModalidad}</h3>
                </div>
                <div className="space-bottom"></div> {/* Espacio al final del rectángulo arriba */}
                <button id="atras" onClick={() => navigate(-1)}>
                        Volver Atrás
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
}

export default MiPerfil;